<template>
    <div 
        class="file_list_item"
        :class="[isCutted && 'cutted_file', isMobile && 'mobile']">
        <div 
            :ref="`file_${file.id}`"
            class="flex items-center cursor-pointer truncate">
            <a 
                v-if="file.is_image"
                :href="file.is_image && file.path"
                class="file_icon_wrap"
                :class="file.is_image && 'lightgallery_wrap'"
                @click="isFolder && setCurrentSource(file.id)">
                <img 
                    class="file_icon lazyload" 
                    :class="file.is_image && 'file_image'"
                    :data-src="file.is_image ? file.path : fileIcon"
                    alt="">
                <span
                    v-if="file.is_dynamic" 
                    class="dynamic_icon_wrap">
                    <i class="fi fi-rr-engine-warning"></i>
                </span>
            </a>
            <template v-else>
                <div 
                    v-if="isFolder"
                    class="file_icon_wrap"
                    @click="isFolder && setCurrentSource(file.id)">
                    <img 
                        class="file_icon lazyload" 
                        :class="file.is_image && 'file_image'"
                        :data-src="file.is_image ? file.path : fileIcon"
                        alt="">
                    <span
                        v-if="file.is_dynamic" 
                        class="dynamic_icon_wrap">
                        <i class="fi fi-rr-engine-warning"></i>
                    </span>
                </div>
                <div 
                    v-else
                    class="file_icon_wrap"
                    @click="fileOpenSwitch(file)">
                    <img 
                        class="file_icon lazyload" 
                        :class="file.is_image && 'file_image'"
                        :data-src="file.is_image ? file.path : fileIcon"
                        alt="">
                    <span
                        v-if="file.is_dynamic" 
                        class="dynamic_icon_wrap">
                        <i class="fi fi-rr-engine-warning"></i>
                    </span>
                </div>
            </template>
            <div class="ml-2 px-2 truncate">
                <div 
                    class="file_name truncate"
                    @click="isFolder && setCurrentSource(file.id)">
                    {{ file.name }}
                </div>
                <div v-if="!isFolder && file.attachment_date" class="attachment_date">
                    <a-icon type="cloud-upload" class="truncate"/> {{ attachmentDate }}
                </div>
                <div
                    v-if="file.folder_path"
                    @click="setCurrentSource(file.folder)"
                    class="file_path truncate">
                    {{ file.folder_path }}
                </div>
            </div>
        </div>
        <div v-if="!isMobile" class="file_desc px-2 truncate">
            {{ file.description }}
        </div>
        <div class="file_size px-2">
            <template v-if="!isFolder">
                {{ getKilobyteSize }} КБ
            </template>
        </div>
        <div class="file_select_wrap">
            <a-checkbox 
                :checked="isChecked"
                class="file_select" 
                @change="changeSelectFile" />
        </div>
        <!-- <div
            v-if="!isFolder" 
            class="file_dropdown_wrap">
            <FileDropdown :file="file"/>
        </div> -->
    </div>
</template>

<script>
import fileSourcesProps from '../../mixins/fileSourcesProps'
import { filesFormat } from '@/utils'

// import FileDropdown from '../FileDropdown.vue'

export default {
    mixins: [fileSourcesProps],
    props: {
        file: {
            type: Object,
            required: true
        },
        setCurrentSource: {
            type: Function,
            default: () => {}
        },
        selectedFiles: {
            type: Object,
            default: () => {}
        },
        cuttedFiles: {
            type: Object,
            default: () => {}
        },
        fileOpenSwitch: {
            type: Function,
            default: () => {}
        },
        mobileApp: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        attachmentDate() {
            return this.$moment(this.file.attachment_date).format('DD.MM.YYYY HH:mm')
        },
        getKilobyteSize() {
            return (this.file.size / 1024).toFixed(1)
        },
        isFolder() {
            return this.file.obj_type === 'folder'
        },
        fileIcon() {
            if(this.isFolder)
                return require(`@/assets/images/files/folder.svg`)

            const extension = filesFormat.find(format => format === this.file.extension)
            if(extension)
                return require(`@/assets/images/files/${extension}.svg`)
            else
                return require(`@/assets/images/files/file.svg`)
        },
        isChecked() {
            return this.selectedFiles.list.includes(this.file)
        },
        isCutted() {
            return this.cuttedFiles?.list?.includes(this.file)
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    created() {
        if(this.file.is_image)
            this.initLightbox()
    },
    methods: {
        changeSelectFile(event) {
            this.selectedFiles.from = this.sourceId
            const isChecked = event.target.checked
            
            if(isChecked)
                this.selectedFiles.list.push(this.file)
            else if(this.selectedFiles.list.includes(this.file)) {
                const fileIndex = this.selectedFiles.list.findIndex(file => file.id === this.file.id)
                this.selectedFiles.list.splice(fileIndex, 1)
                if(this.cuttedFiles.list && this.cuttedFiles.list.includes(this.file)) {
                    const fileIndex = this.cuttedFiles.list.findIndex(file => file.id === this.file.id)
                    this.cuttedFiles.list.splice(fileIndex, 1)
                }
            }
            this.$emit('switchCheckbox')
        },
        initLightbox() {
            this.$nextTick(() => {
                const lightboxWrap = this.$refs[`file_${this.file.id}`],
                    lightbox = lightboxWrap.querySelector('.lightgallery_wrap')
                if(lightbox)
                    lightGallery(lightboxWrap, {
                        selector: ".lightgallery_wrap",
                        thumbnail: true,
                        rotateLeft: true,
                        rotateRight: true,
                        flipHorizontal: false,
                        flipVertical: false,
                        fullScreen: true,
                        animateThumb: true,
                        showThumbByDefault: true,
                        download: this.mobileApp ? false : true,
                        speed: 300
                    })
            })
        },
    },
}
</script>

<style scoped lang="scss">
.attachment_date {
    opacity: 0.6;
    font-size: 0.8rem;
}
.cutted_file {
    opacity: 0.5;
}
.file_list_item {
    display: grid;        
    grid-template-columns: 1fr 1fr 80px 20px;

    &:not(:last-child) {
        border-bottom: 1px solid var(--borderColor);
    }
    &:not(.mobile) {
        padding: 8px;
    }

    &.mobile {
        grid-template-columns: 2fr 1fr 20px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
.file_path {
    margin-top: 4px;
    font-size: 0.6rem;
}
.file_desc {
    display: flex;
    align-items: flex-end;
}

.file_icon_wrap {
    flex-shrink: 0;
    position: relative;

    .dynamic_icon_wrap {
        position: absolute;
        top: -5px;
        right: -3px;

        font-size: 1rem;
    }
}
.file_image {
    border: 1px solid var(--borderColor);
    border-radius: 4px;
}
.file_icon {
    width: 30px;
    height: 30px;
    object-fit: cover;

}

.file_dropdown_wrap {
    display: flex;
    justify-content: center;
}
</style>